import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { Row, Col } from "reactstrap";

import { find, filter } from "lodash";

import Loading from "components/Loading";

import Emitter from "helpers/emitter_helper";

import { types } from '../../config';

class Tramite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedules: [],
      schedule: null,
      scheduleName: null,
      validated: false
    };
  }

  isValidated() {
    return this.state.validated;
  }

  componentDidMount() {
    Emitter.on('ATYPE_SELECTED', (type) => {
      setTimeout(() => {
        let t = find(types, (t) => t.code == type);
        let fs = [];

        t.schedules.forEach((s) => {
          const os = find(this.props.schedules, { id: s });

          if (os) {
            fs.push(os);
          }
        });
      
        this.setState({ schedules: fs });
      }, 1000);
    });

    Emitter.on('TRAMITE_FROM_URL', (value) => {
      if (value) {
        const gschedule = find(this.props.groupedSchedules, { name: value });
        const schedule = find(this.props.schedules, { id: gschedule.id });

        this.setState({ schedule: gschedule.id, scheduleName: schedule.name, validated: true });

        Emitter.emit('SET_SCHEDULE', value);
        Emitter.emit('BREADCRUMB_SCHEDULE', value);

        Emitter.emit('NEXT_BUTTON_CLICK', true);
      }
    });
  }

  componentWillUnmount() {
    Emitter.off('ATYPE_SELECTED');
    Emitter.off('TRAMITE_FROM_URL');
  }

  setSchedule(event) {
    if (this.state.schedule && this.state.schedule != event.target.value) {
      Emitter.emit('RESET', true);
    }

    let id = parseInt(event.target.value);

    let schedule = find(this.props.schedules, { id: id });

    this.setState({ schedule: id, scheduleName: schedule.name, validated: true });

    Emitter.emit('SET_SCHEDULE', schedule.name);
    
    Emitter.emit('BRANCH_SELECTED', {
      branch: this.props.branches[0].id,
      schedules: filter(this.state.schedules, (s) => {
        return s.id == id && !!find(s.branches, ['id', this.props.branches[0].id]);
      })
    });

    Emitter.emit('BREADCRUMB_BRANCH', this.props.branches[0].name);
    Emitter.emit('BREADCRUMB_SCHEDULE', schedule.name);
  }

  render() {
    return (
      <React.Fragment>
        <Row className="justify-content-center">
          {this.props.loading && (
            <Loading />
          )}
        </Row>
        <Row>
          {this.state.schedules.map((schedule) => (
            <Col key={schedule.id} md={6} xs={12}>
              <div className="custom-radio mr-3">
                <input id={'schedule-' + schedule.id} name="schedules" type="radio" value={schedule.id} checked={this.state.schedule === schedule.id} onChange={this.setSchedule.bind(this)} />
                <label htmlFor={'schedule-' + schedule.id}>{schedule.name}</label>
              </div>
            </Col>
          )
          )}
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { loading, schedules, branches, error } = state.Home;
  return {
    loading, schedules, branches, error
  };
}

export default connect(mapStateToProps, {
}, null, { forwardRef: true })(Tramite);

