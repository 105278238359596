import store from "store";
import { get, post, put } from "../helpers/api_helper";
import * as url from "../helpers/url_helper";
import moment from "moment-timezone";


const getAllSchedules = () => {
  return get(url.SCHEDULES + '?paused=false&reduceResponse=true')
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err.response.data;
    });
};

const getAllSchedulesByDateAndBranch = (scheduleId, branchId, strDate) => {
    return get(url.SCHEDULES + '/' + scheduleId + '/branch/' + branchId + '/availability?strDate=' + strDate)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err.response.data;
    });
};

const getAllBranches = () => {
  return get(url.BRANCHES)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err.response.data;
    });
};


const getAllDates = (scheduleId, branchId, strDate) => {
  return get(url.DATES + '?scheduleId=' + scheduleId + '&branchId=' + branchId + '&strDate=' + strDate)
    .then(response => {
      return getBranchTimeZone(branchId).then( timezoneRes => {
        response.branchTimeZone = timezoneRes;
        response.slots.forEach(slot => {
          const startAtZoned = moment(slot.startDate).tz(timezoneRes).add(slot.daySavingTimeDifference, "seconds");
          slot.formattedStartHour = startAtZoned.format('HH:mm');
        });
        return response;
      });
    })
    .catch(err => {
      console.error('error', err);
      throw err.response.data;
    });
};

const insertAppointment = data => {
  return post(url.APPOINTMENTS, data)
    .then(response => {
      return response;
    })
    .catch(err => {
      throw err.response.data;
    });
};

const getBranchTimeZone = (branchId) => {
  return get(`/api/v1/timezone/${branchId}`)
  .catch( err => {
    throw err.response.data;
  });
}

export {
  getAllSchedules,
  getAllSchedulesByDateAndBranch,
  getAllBranches,
  getAllDates,
  insertAppointment
};

function f(m) {
  return m.format("YYYY-MM-DDTHH:mm:ssZ");
}