import React, { Component } from "react";
import Select from 'react-select';
import PropTypes from "prop-types";
import es from "../assets/images/es.svg";
import eu from "../assets/images/eu.svg";

class LanguageSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
        selected: 'es',
        countries: [
            {
                value: 'es',
                icon: es
            },
            {
                value: 'eu',
                icon: eu
            }
        ]
    };
  }

  componentDidMount() {
    let selected = this.state.countries.find((c) => c.value == this.props.selected);
    this.setState({ selected });
  }

  handleChange(option) {
    this.setState({ selected: option });
    this.props.handleChange(option.value);
  }

  render() {
    return <Select
        placeholder={''}
        value={this.state.selected}
        options={this.state.countries}
        onChange={(option, e) => {
            this.handleChange(option);
        }}
        getOptionLabel={e => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={e.icon} width={24} />
            </div>
        )}       
    />
  }
}

LanguageSelector.propTypes = {
  selected: PropTypes.string,
  handleChange: PropTypes.func
};

export default LanguageSelector;
