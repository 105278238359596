import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { Row, Col } from "reactstrap";

import { withTranslation } from "react-i18next";

import Loading from "components/Loading";

import Emitter from "helpers/emitter_helper";

class Atencion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [
        { code: 'atencion_presencial', name: this.props.t('atencion_presencial')},
        { code: 'atencion_telefonica', name: this.props.t('atencion_telefonica')},
      ],
      type: null,
      validated: false
    };
  }

  isValidated() {
    return this.state.validated;
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  setType(event) {
    this.setState({ type: event.target.value, validated: true }, () => {
      Emitter.emit('ATYPE_SELECTED', event.target.value);
    });    
  }

  render() {
    return (
      <React.Fragment>
        <Row className="justify-content-center">
          {this.props.loading && (
            <Loading />
          )}
        </Row>
        <Row>
          {this.state.types.map((type) => (
            <Col key={type.code} md={6} xs={12}>
              <div className="custom-radio mr-3">
                <input id={'type-' + type.code} name="types" type="radio" value={type.code} checked={this.state.type === type.code} onChange={this.setType.bind(this)} />
                <label htmlFor={'type-' + type.code}>{this.props.t(type.code)}</label>
              </div>
            </Col>
          )
          )}
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {};
}

export default connect(mapStateToProps, {
}, null, { forwardRef: true })(withTranslation('translations', { withRef: true })(Atencion));
