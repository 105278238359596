import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      es: {
        translations: {
          document_title: 'Mancomunidad de la Comarca de Pamplona - Solicitud de turnos',
          title: 'Solicitud de turnos',
          anterior: 'Anterior',
          siguiente: 'Siguiente',
          tipo_atencion: 'Tipo de atención',
          atencion_presencial: 'Cita presencial',
          atencion_telefonica: 'Cita telefónica',
          tipo_analisis: 'Tipo de Trámite',
          disponibilidad: 'Disponibilidad',
          sedes: 'Sedes',
          datos: 'Datos',
          fecha_hora: 'Fecha y hora',
          confirmacion: 'Confirmación',
          finalizar: 'Finalizar',
          aceptar: 'Aceptar',
          cancelar: 'Cancelar',
          error: 'Error!',
          error_max: 'Se alcanzó el límite máximo establecido de citas por cliente por día.',
          error_general: 'Ha ocurrido un error. Por favor, intente nuevamente.',
          gracias: '¡Gracias!',
          email_success: 'En breve recibirá un SMS y/o un correo electrónico con la información de su cita.',
          por_sede: 'Por sede',
          por_fecha: 'Por fecha',
          sedes_count: 'SEDES',
          sin_horarios: 'No hay horarios disponibles.',
          seleccione: 'Seleccione',
          campos_req: 'Por favor, complete los campos marcados con',
          nombre: 'Nombre',
          nombre_req: 'El nombre es requerido',
          apellido: 'Apellido',
          apellido_req: 'El apellido es requerido',
          dni: 'DNI o Pasaporte',
          dni_req: 'El DNI o Pasaporte es requerido',
          telefono: 'Teléfono celular',
          telefono_req: 'El teléfono es requerido',
          telefono_inv: 'El teléfono sólo admite números',
          email: 'Email',
          email_req: 'El email es requerido',
          email_inv: 'El email es inválido',
          email_conf: 'Los emails no coinciden',
          confirmar_email: 'Confirmar email',
          confirmar_email_req: 'Confirmar email es requerido',
          orden: 'Orden médica',
          orden_attach: 'Adjuntar orden médica',
          idioma: 'Idioma de atención',
          idioma_req: 'El idioma de atención es requerido',
          es: 'Castellano',
          eu: 'Euskera',
          motivo: 'Motivo',
          motivo_req: 'El motivo es requerido',
          terminos: 'Términos y condiciones',
          terminos_req: 'Debe aceptar los Términos y Condiciones para continuar con la cita.',
          terminos_contenido: 'Le informamos que los datos recabados en el presente formulario son necesarios para la prestación del servicio y se incorporarán, con su consentimiento, a un fichero responsabilidad de SCPSA con la finalidad de gestionar la atención a la ciudadanía. Dichos datos no serán cedidos a terceros salvo las previsiones legales. Asimismo, le informamos que el responsable del fichero será SCPSA a quien podrá dirigirse con el fin de ejercitar sus derechos de acceso, rectificación, supresión y el resto de los derechos que le asisten a través del correo electrónico <a href="mailto:protecciondatos@mcp.es">protecciondatos@mcp.es</a>.',
          different_timezone_msg: 'Se encuentra en una zona horaria distinta a la de la sucursal. Todos los horarios se muestran en la zona horaria: {{branchTimeZone}}.'
        }
      },
      eu: {
        translations: {
          document_title: 'Iruñerriko Mankomunitatea - Hitzordu eskaera',
          title: 'Hitzordu eskaera',
          anterior: 'Aurrekoa',
          siguiente: 'Hurrengoa',
          tipo_atencion: 'Arreta mota',
          atencion_presencial: 'Aurrez aurreko arreta',
          atencion_telefonica: 'Arreta telefonikoa',
          tipo_analisis: 'Izapide mota',
          disponibilidad: 'Eskuragarritasuna',
          sedes: 'Egoitza',
          datos: 'Datuak',
          fecha_hora: 'Data eta ordua',
          confirmacion: 'Baieztapena',
          finalizar: 'Amaitu',
          aceptar: 'Onartu',
          cancelar: 'Utzi',
          error: 'Akatsa!',
          error_max: 'Bezero bakoitzeko eguneko hitzorduen gehienezko ezarritako muga gainditu da.',
          error_general: 'Akats bat gertatu da. Mesedez, saiatu berriro.',
          gracias: 'Eskerrik',
          email_success: 'Zure hitzorduaren informazioa duen SMS bat eta/edo mezu elektroniko bat jasoko duzu laster.',
          por_sede: 'Egoitza elektronikoaren bidez',
          por_fecha: 'Dataren arabera',
          sedes_count: 'EGOITZAK',
          sin_horarios: 'Ez dago hitzordu eskuragarririk',
          campos_req: 'Mesedez (*)markatutako eremuak bete',
          seleccione: 'Hautatu',
          nombre: 'Izena',
          nombre_req: 'Izena beharrezkoa da',
          apellido: 'Abizena',
          apellido_req: 'Abizena beharrezkoa da',
          dni: 'NANa edo Pasaportea',
          dni_req: 'NANa edo Pasaportea beharrezkoa da',
          telefono: 'Mugikorra',
          telefono_req: 'Telefono zenbakia beharrezkoa da',
          telefono_inv: 'Telefonoak zenbakiak soilik onartzen ditu',
          email: 'Emaila',
          email_req: 'Emaila beharrezkoa da',
          email_inv: 'Emaila baliogabea da',
          email_conf: 'Emailak ez datoz bat',
          confirmar_email: 'Emaila baieztatu',
          confirmar_email_req: 'Emaila baieztatzea beharrezkoa da',
          orden: 'Medikuntza agindua',
          orden_attach: 'Medikuntza agindua erantsi',
          idioma: 'Arreta hizkuntza',
          idioma_req: 'Arreta hizkuntza beharrezkoa da',
          es: 'Gaztelera',
          eu: 'Euskara',
          motivo: 'Zergatia',
          motivo_req: 'Zergatia beharrezkoa da',
          terminos: 'Baldintzak',
          terminos_req: 'Terminoak eta baldintzak onartu behar dituzu hitzorduarekin jarraitzeko.',
          terminos_contenido: 'Jakinarazi behar dizugu formulario honetan bildutako datuak beharrezkoak direla zerbitzua eman ahal izateko eta, zure baimena izanda, SCPSAren erantzukizunpeko fitxategi batean sartuko direla. Helburua izango da herritarren arreta kudeatzea. Datu horiek ez zaizkio beste inori lagako, legezko aurreikuspenen kasuan izan ezik. Halaber, jakinarazi behar dizugu fitxategiaren arduraduna SCPSA izango dela eta harengana jo ahal izango duzula datuak eskuratzeko, zuzentzeko eta ezabatzeko eskubideak eta dauzkazun gainerakoak gauzatzeko helburuarekin, <a href="mailto:protecciondatos@mcp.es">protecciondatos@mcp.es</a> helbide elektronikoaren bidez.',
          different_timezone_msg: 'Sukurtsala ez den ordu-eremu ezberdin batean zaude. Ordu guztiak ordu-eremuan bistaratzen dira: {{branchTimeZone}}.'
        } 
      }
    }
  });


export default i18n;
