import React from "react";
// Redux
import { connect } from "react-redux";

import { Container, Row, Col } from "reactstrap";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import { find, filter } from "lodash";

import { withTranslation } from "react-i18next";

import i18n from "../i18n";

import logo from "../assets/images/logo.png";

import Emitter from "helpers/emitter_helper";

import Wizard from "components/Wizard";

import LanguageSelector from "components/LanguageSelector";

import Atencion from "./Steps/Atencion";
import Tramite from "./Steps/Tramite";
import Fecha from "./Steps/Fecha";
import Datos from "./Steps/Datos";
import Confirmacion from "./Steps/Confirmacion";
import ReactGA from 'react-ga';

import { getBranches, getSchedules, createAppointment } from "store/actions";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: null,
      steps: [
      { stepName: this.props.t('tipo_atencion'), stepKey: 'tipo_atencion', component: Atencion, stepIcon: "mdi mdi-phone", translateKey: "tipo_atencion" },
      { stepName: this.props.t('tipo_analisis'), stepKey: 'tipo_analisis', component: Tramite, stepIcon: "mdi mdi-clipboard-text", translateKey: "tipo_analisis" },
      { stepName: this.props.t('fecha_hora'), stepKey: 'fecha_hora', component: Fecha, stepIcon: "mdi mdi-calendar", translateKey: "fecha_hora" },
      { stepName: this.props.t('datos'), stepKey: 'datos', component: Datos, stepIcon: "mdi mdi-account-circle", translateKey: "datos" },
      { stepName: this.props.t('confirmacion'), stepKey: 'confirmacion', component: Confirmacion, stepIcon: "mdi mdi-check-circle", translateKey: "confirmacion" }
      ],
      breadcrumb: {
        tramite: {
          title: '',
          icon: 'mdi mdi-clipboard-text'
        },
        sucursal: {
          title: '',
          icon: 'mdi mdi-domain'
        },
        fecha: {
          title: '',
          icon: 'mdi mdi-calendar'
        }
      },
      success_msg: false,
      error_msg: false,
      lang: 'es'
    };
  }

  componentDidMount() {
    this.props.getBranches();
    this.props.getSchedules(null, (result) => this.onGetSchedules(result));
  }

  finish(allStates) {
    ReactGA.event({
      evnet: 'click',
      category: 'Cita',
      action: 'Cita confirmada'
    });
    const tramites = filter(this.props.schedules, (s) => {
      return s.name == allStates['tipo_analisis'].scheduleName;
    }); 
    const tramite = filter(tramites, (s) => {
      return find(s.branches, { id: this.props.branches[0].id });
    })[0];

    let payload = {
      branch: {
        id: this.props.branches[0].id
      },
      schedule: {
        id: tramite.id
      },
      startAt: allStates['fecha_hora'].startAt,
      endAt: allStates['fecha_hora'].endAt,
      customer: {
        firstName: allStates['datos'].values.firstName,
        lastName: allStates['datos'].values.lastName,
        cedulaIdentidad: allStates['datos'].values.cedulaIdentidad,
        phone: allStates['datos'].values.phone,
        email: allStates['datos'].values.email,
        phoneCompany: {
          name: this.props.t(allStates['datos'].values.language)
        }
      },
      reason: allStates['datos'].values.reason
    }

    if (!payload.customer.email) {
      delete payload.customer.email;
    }
    
    this.props.createAppointment(payload, (result) => this.onAppointmentCreate(result));
  }

  onAppointmentCreate(result) {
    if (result) {
      this.setState({ success_msg: true });
    } else {
      this.setState({ error_msg: true });
    }
  }

  onGetSchedules(result) {
    if (result) {
      if (this.props.match.params.tramite) {
        setTimeout(() => {
          Emitter.emit('TRAMITE_FROM_URL', this.props.match.params.tramite);
        }, 1500);
      }
    }
  }

  switchLanguage(lang) {
    i18n.changeLanguage(lang);

    document.title = this.props.t('document_title');

    let steps = this.state.steps;

    steps = steps.map((s) => {
      s.stepName = this.props.t(s.translateKey);

      return s;
    });

    Emitter.emit('LANGUAGE_CHANGE', lang);

    this.setState({ steps, lang });
  }

  render() {
    return (
      <Container>
        <Row className="justify-content-center mt-3 mb-3">
          <Col>
            <div className="text-center">
              <img src={logo} alt="Mancomunidad de la Comarca de Pamplona" className="logo img-fluid" />
              <div className="float-right d-inline" style={{width: 80}}>
                <LanguageSelector selected={this.state.lang} handleChange={this.switchLanguage.bind(this)} />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Wizard
              title={this.props.t('title')}
              steps={this.state.steps}
              breadcrumb={this.state.breadcrumb}
              headerTextCenter
              validate
              color="primary"
              finishButtonText={this.props.t('finalizar')}
              finishButtonClick={this.finish.bind(this)}
              finishButtonClasses="btn-success"
              previousButtonText={this.props.t('anterior')}
              nextButtonText={this.props.t('siguiente')}
              nextButtonClasses="btn-primary"
              type={this.state.type}
            />
            {this.state.success_msg ? (
              <SweetAlert
                title={this.props.t('gracias')}
                success
                confirmBtnBsStyle="success"
                confirmBtnText={this.props.t('aceptar')}
                onConfirm={() => {
                  if (process.env.REACT_APP_THANK_YOU_PAGE_URL) {
                    window.top.location = process.env.REACT_APP_THANK_YOU_PAGE_URL;
                  } else {
                    window.location.reload();
                  }
                }}
              >
                {this.props.t('email_success')}
              </SweetAlert>
            ) : null}
            {this.state.error_msg ? (
              <SweetAlert
                title={this.props.t('error')}
                error
                confirmBtnBsStyle="danger"
                confirmBtnText={this.props.t('aceptar')}
                onConfirm={() => this.setState({ error_msg: false })}
              >
                {this.props.error.apiMessage == 'API_APPOINTMENTS_CREATE_MAX_NUMBER_OF_APPOINTMENTS_PER_CUSTOMER_PER_DAY_REACHED' ? this.props.t('error_max') : this.props.t('error_general')}
              </SweetAlert>
            ) : null}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { schedules, branches, error } = state.Home;
  return {
    schedules, branches, error
  };
}

export default connect(mapStateToProps, {
  getBranches,
  getSchedules,
  createAppointment
})(withTranslation('translations')(Home));
