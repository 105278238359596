import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { Row, Col } from "reactstrap";

import { withTranslation } from "react-i18next";

import Loading from "components/Loading";

class Confirmacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <React.Fragment>
        {this.props.loading ?
          <Row>
            <Col className="d-flex justify-content-center">
              <Loading />
            </Col>
          </Row>
          : <>
          <Row className="mt-3">
            <Col>
              <div className="form-group">
                <label>{this.props.t('nombre')}</label><br />
                <span>{this.props.wizardData['datos'] ? this.props.wizardData['datos'].values.firstName : '-'}</span>
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label>{this.props.t('apellido')}</label><br />
                <span>{this.props.wizardData['datos'] ? this.props.wizardData['datos'].values.lastName : '-'}</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <div className="form-group">
                <label>{this.props.t('dni')}</label><br />
                <span>{this.props.wizardData['datos'] ? this.props.wizardData['datos'].values.cedulaIdentidad : '-'}</span>
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label>{this.props.t('telefono')}</label><br />
                <span>{this.props.wizardData['datos'] ? this.props.wizardData['datos'].values.phone : '-'}</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <div className="form-group">
                <label>{this.props.t('email')}</label><br />
                <span>{this.props.wizardData['datos'] ? this.props.wizardData['datos'].values.email || '-' : '-'}</span>
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label>{this.props.t('idioma')}</label><br />
                <span>{this.props.wizardData['datos'] ? this.props.t(this.props.wizardData['datos'].values.language) : '-'}</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <div className="form-group">
                <label>{this.props.t('motivo')}</label><br />
                <span>{this.props.wizardData['datos'] ? this.props.wizardData['datos'].values.reason : '-'}</span>
              </div>
            </Col>
          </Row>
        </>
      }
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { loading } = state.Home;
  return {
    loading
  };
}

export default connect(mapStateToProps, {
}, null, { forwardRef: true })(withTranslation('translations', { withRef: true })(Confirmacion));

