module.exports = {
    types: [
        {
            code: 'atencion_presencial',
            schedules: [2,7,8,9],
        },
        {
            code: 'atencion_telefonica',
            schedules: [11],
        }
    ]
}
