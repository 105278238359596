import { takeEvery, put, call } from "redux-saga/effects";
import moment from "moment-timezone";

// Login Redux States
import { SCHEDULES_GET, BRANCHES_GET, DATES_GET, CREATE_APPOINTMENT, DATES_GET_BY_BRANCH } from "./actionTypes";
import { apiError, getSchedulesSuccess, getBranchesSuccess, getDatesSuccess, getDatesByBranchSuccess, 
  createAppointmentSuccess } from "./actions";

import {
  getAllSchedules,
  getAllBranches,
  getAllDates,
  insertAppointment
} from "../../services/appointments";

function* getSchedules({ payload }) {
  try {
    let lsSchedules = JSON.parse(localStorage.getItem('schedules'));
    let response;

    if (!lsSchedules) {
      response = yield call(getAllSchedules);
      saveSchedulesToStorage(response);
    } else {
      var diff = moment().diff(moment(lsSchedules.retrieved_at), 'minutes');

      if (diff > 30) {
        response = yield call(getAllSchedules);
        saveSchedulesToStorage(response);
      } else {
        response = lsSchedules.schedules;
      }
    }

    yield put(getSchedulesSuccess(response));

    payload.callback(true);
  } catch (error) {
    yield put(apiError(error));

    payload.callback(false);
  }
}

function saveSchedulesToStorage(response) {
  const lsSchedules = { retrieved_at: moment().toISOString(), schedules: response };

  localStorage.setItem('schedules', JSON.stringify(lsSchedules));
}

function* getBranches() {
  try {
    let lsBranches = JSON.parse(localStorage.getItem('branches'));
    let response;

    if (!lsBranches) {
      response = yield call(getAllBranches);
      saveBranchesToStorage(response);
    } else {
      var diff = moment().diff(moment(lsBranches.retrieved_at), 'minutes');

      if (diff > 30) {
        response = yield call(getAllBranches);
        saveBranchesToStorage(response);
      } else {
        response = lsBranches.branches;
      }
    }
    yield put(getBranchesSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function saveBranchesToStorage(response) {
  const lsBranches = { retrieved_at: moment().toISOString(), branches: response };

  localStorage.setItem('branches', JSON.stringify(lsBranches));
}

function* getDates({ payload }) {
  try {
    const response = yield call(getAllDates, payload.scheduleId, payload.branchId, payload.strDate);
    response.branchId = payload.branchId;
    response.scheduleName = payload.scheduleName;
        yield put(getDatesSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getDatesByBranch({ payload }) {
  try {
    const response = yield call(getAllDates, payload.scheduleId, payload.branchId, payload.strDate);
    response.branchId = payload.branchId;
    response.scheduleName = payload.scheduleName;
    yield put(getDatesByBranchSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* createAppointment({ payload: { appointment, callback } }) {
  try {
    const response = yield call(insertAppointment, appointment);
    yield put(createAppointmentSuccess(response));

    callback(true);
  } catch (error) {
    yield put(apiError(error));

    callback(false);
  }
}

function* HomeSaga() {
  yield takeEvery(SCHEDULES_GET, getSchedules);
  yield takeEvery(BRANCHES_GET, getBranches);
  yield takeEvery(DATES_GET, getDates);
  yield takeEvery(DATES_GET_BY_BRANCH, getDatesByBranch);
  yield takeEvery(CREATE_APPOINTMENT, createAppointment);
}

export default HomeSaga;
