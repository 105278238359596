import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { Row, Col } from "reactstrap";

import { withTranslation } from "react-i18next";

import { Formik, Field, Form, ErrorMessage } from "formik";

import * as Yup from "yup";

import ReCAPTCHA from "react-google-recaptcha";

import SweetAlert from "react-bootstrap-sweetalert";

import Emitter from "helpers/emitter_helper";

class Datos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      values: {
        firstName: '',
        lastName: '',
        cedulaIdentidad: '',
        phone: '',
        email: '',
        confirmEmail: ''
      },
      passport: null,
      terms: false,
      showTerms: false,
      recaptchaToken: null
    };

    this.formRef = React.createRef();
    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    Emitter.on('LANGUAGE_CHANGE', (value) => {
      if (value) {
        this.formRef.current.setErrors({
          firstName: this.props.t('nombre_req'),
          lastName: this.props.t('apellido_req'),
          cedulaIdentidad: this.props.t('dni_req'),
          phone: this.props.t('telefono_req'),
          language: this.props.t('idioma_req'),
          reason: this.props.t('motivo_req')
        });
      }
    });
  }

  isValidated() {
    //return this.state.validated && this.state.passport;
    return this.state.validated && this.state.terms && this.state.recaptchaToken;
  }

  handleChange(values) {
    setTimeout(function () {
      this.setState({ validated: this.formRef.current.isValid, values });
    }.bind(this), 100);
  }

  handleTermsChange(event) {
    this.setState({ terms: event.target.checked });
  }

  handleRecaptchaChange(recaptchaToken) {
    this.setState({ recaptchaToken });
  }

  handleFile(file) {
    this.setState({ passport: file });
  }

  render() {
    return (
      <React.Fragment>
        <Formik
          validateOnMount={true}
          innerRef={this.formRef}
          initialValues={{
            firstName: '',
            lastName: '',
            cedulaIdentidad: '',
            phone: '',
            email: '',
            confirmEmail: '',
            language: '',
            reason: ''
          }}
          initialTouched={{
            firstName: true,
            lastName: true,
            cedulaIdentidad: true,
            phone: true,
            email: true,
            confirmEmail: true,
            language: true,
            reason: true
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string()
              .required(this.props.t('nombre_req')),
            lastName: Yup.string()
              .required(this.props.t('apellido_req')),
            cedulaIdentidad: Yup.string()
              .required(this.props.t('dni_req')),
            phone: Yup.string()
              .required(this.props.t('telefono_req'))
              .matches(/^[0-9]+$/, this.props.t('telefono_inv')),
            email: Yup.string()
              .email(this.props.t('email_inv')),
            confirmEmail: Yup.string()
              .oneOf([Yup.ref('email'), null], this.props.t('email_conf')),
            language: Yup.string()
              .required(this.props.t('idioma_req')),
            reason: Yup.string()
              .required(this.props.t('motivo_req'))
          })}>
          {({ values }) => (
            <Form onKeyUp={() => this.handleChange(values)}>
              <Row>
                <Col>
                  <div className="alert alert-info">
                    <strong>{this.props.t('campos_req')} <span className="text-danger">*</span></strong>
                  </div>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col>
                  <div className="form-group">
                    <label>{this.props.t('nombre')} <span className="text-danger">*</span></label>
                    <Field
                      className="form-control"
                      name="firstName"
                      type="text"
                    />
                    <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label>{this.props.t('apellido')} <span className="text-danger">*</span></label>
                    <Field
                      className="form-control"
                      name="lastName"
                      type="text"
                    />
                    <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div className="form-group">
                    <label>{this.props.t('dni')} <span className="text-danger">*</span></label>
                    <Field
                      className="form-control"
                      name="cedulaIdentidad"
                      type="text"
                    />
                    <ErrorMessage name="cedulaIdentidad" component="div" className="invalid-feedback" />
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label>{this.props.t('telefono')} <span className="text-danger">*</span></label>
                    <Field
                      className="form-control"
                      name="phone"
                      type="text"
                    />
                    <ErrorMessage name="phone" component="div" className="invalid-feedback" />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div className="form-group">
                    <label>{this.props.t('email')}</label>
                    <Field
                      className="form-control"
                      name="email"
                      type="text"
                    />
                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label>{this.props.t('confirmar_email')}</label>
                    <Field
                      className="form-control"
                      name="confirmEmail"
                      type="text"
                    />
                    <ErrorMessage name="confirmEmail" component="div" className="invalid-feedback" />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div className="form-group">
                    <label>{this.props.t('idioma')} <span className="text-danger">*</span></label>
                    <Field
                      className="form-control"
                      name="language"
                      as="select">
                        <option value="">{this.props.t('seleccione')}</option>
                        <option value="es">{this.props.t('es')}</option>
                        <option value="eu">{this.props.t('eu')}</option>
                    </Field>
                    <ErrorMessage name="language" component="div" className="invalid-feedback" />
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label>{this.props.t('motivo')} <span className="text-danger">*</span></label>
                    <Field
                      className="form-control"
                      name="reason"
                      as="textarea"
                      rows="5"
                    />
                    <ErrorMessage name="reason" component="div" className="invalid-feedback" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-group">
                    <Field
                      className="mr-2"
                      name="terms"
                      type="checkbox"
                      checked={this.state.terms}
                      onChange={this.handleTermsChange.bind(this)}
                    />
                    <a className="link" onClick={() => this.setState({ showTerms: true })}>{this.props.t('terminos')}</a> <span className="text-danger">*</span>
                    <div className="invalid-feedback">{this.props.t('terminos_req')}</div>
                    <ErrorMessage name="terms" component="div" className="invalid-feedback" />
                  </div>
                </Col>
                <Col>
                   <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={this.handleRecaptchaChange.bind(this)} />
                </Col>
              </Row>
              {/* <Row className="mt-3">
                <Col md={6}>
                  <div className="form-group">
                    <label>{this.props.t('orden')} <span className="text-danger">*</span></label><br/>
                    <input type="file" name="passport" id="passport" className="inputfile inputfile-custom" onChange={(e) => this.handleFile(e.target.files[0])} />
                    <label htmlFor="passport"><span>{this.state.passport ? this.state.passport.name : ''}</span> <strong><svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" /></svg> {this.props.t('orden_attach')}&hellip;</strong></label>
                  </div>
                </Col>
              </Row> */}
            </Form>
          )}
          </Formik>
          <SweetAlert
            title={this.props.t('terminos')}
            onConfirm={() => this.setState({ showTerms: false })}
            show={this.state.showTerms}
            customButtons={
              <React.Fragment>
                <button className="btn btn-secondary mr-3" onClick={() => this.setState({ showTerms: false })}>{this.props.t('cancelar')}</button>
                <button className="btn btn-primary" onClick={() => this.setState({ showTerms: false, terms: true })}>{this.props.t('aceptar')}</button>
              </React.Fragment>
            }
          >
            <div dangerouslySetInnerHTML={{ __html: this.props.t('terminos_contenido').replace(/(<? *script)/gi, 'illegalscript') }} >
            </div>
          </SweetAlert>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
  };
}

export default connect(mapStateToProps, {
}, null, { forwardRef: true })(withTranslation('translations', { withRef: true })(Datos));

