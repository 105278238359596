import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { Row, Col } from "reactstrap";

import "flatpickr/dist/themes/material_blue.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { Catalan } from "flatpickr/dist/l10n/cat.js";
import Flatpickr from "react-flatpickr";

import moment from "moment-timezone";

import { filter, find, sortBy } from "lodash";

import { withTranslation } from "react-i18next";

import i18n from "../../i18n";

import Loading from "components/Loading";

import Emitter from "helpers/emitter_helper";

import { getDates } from "store/actions";

class Fecha extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validated: false,
      branchId: null,
      hours: [],
      date: null,
      scheduleName: null,
      hour: null,
      startAt: null,
      endAt: null,
      key: 1
    };
  }

  componentDidMount() {
    Emitter.on('BRANCH_SELECTED', (obj) => {
      this.setState({ branchId: obj.branch });

      obj.schedules.forEach((s) => {
        this.props.getDates(s.name, s.id, obj.branch, moment().format('YYYY-MM-DDTHH:mm:ssZZ'));
      });
    });

    Emitter.on('RESET', (value) => {
      this.setState({ key: this.state.key + 1 });
    });

    Emitter.on('SET_SCHEDULE', (value) => {
      if (value) {
        this.setState({ scheduleName: value });
      }
    });

    this.setState({userTimeZone: moment.tz.guess()});

  }

  componentWillUnmount() {
    Emitter.off('BRANCH_SELECTED');
    Emitter.off('RESET');
    Emitter.off('SET_SCHEDULE');
  }

  //eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props !== prevProps && !this.props.enabledDates.length) {
      this.setState({ hours: [], date: null, hour: null, startAt: null, endAt: null });
    }

    const dates = this.props.enabledDates.map((d) => {
      return moment(d);
    });
    if (this.state.userTimeZone && this.props.branchTimeZone && (prevProps.branchTimeZone !== this.props.branchTimeZone)) {
      const userTimeZoneSameAsBranchTimeZone = this.compareTimeZones(
        this.state.userTimeZone,
        this.props.branchTimeZone,
        moment.min(dates),
        moment.max(dates)
      );
      this.setState({showDifferentTimeZoneMsg: !userTimeZoneSameAsBranchTimeZone});
    }
  }

  isValidated() {
    return this.state.startAt && this.state.endAt;
  }

  handleMonthChange(selectedDates, dateStr, instance) {
    if (!this.props.loading) {
      this.props.getDates(this.state.scheduleName, this.props.wizardData.tipo_analisis.schedule, this.state.branchId, moment(instance.currentYear + '-' + (instance.currentMonth + 1) + '-01', 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ssZZ'));
    }

    this.setState({ hours: [], startAt: null, endAt: null, date: null, hour: null });
  }

  handleYearChange(selectedDates, dateStr, instance) {
    if (!this.props.loading) {
      this.props.getDates(this.state.scheduleName, this.props.wizardData.tipo_analisis.schedule, this.state.branchId, moment(instance.currentYear + '-' + (instance.currentMonth + 1) + '-01', 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ssZZ'));
    }

    this.setState({ hours: [], startAt: null, endAt: null, date: null, hour: null });
  }

  selectDate(date) {
    let selected = moment(date.date[0]).format('YYYY-MM-DD');
    let filtered = filter(this.props.dates, { formattedDate: selected });
    
    let hours = sortBy(filtered.filter((h) => { return h.availability > 0 }), 'formattedStartHour');

    Emitter.emit('BREADCRUMB_DATE', moment(date.date[0]).format('DD/MM/YYYY'));
    
    this.setState({ hours, date, hour: null });
  }

  setHour(event) {
    let hour = find(this.state.hours, { formattedStartHour: event.target.value });
    
    this.setState({ 
      startAt: moment(hour.zonedStartDate).format('YYYY-MM-DDTHH:mm:ssZZ'), 
      endAt: moment(hour.zonedStartDate).add(hour.defaultDuration, 'minutes').format('YYYY-MM-DDTHH:mm:ssZZ'),
      hour: event.target.value
    });

    const startAtZoned = moment(hour.startDate).tz(this.props.branchTimeZone).add(hour.daySavingTimeDifference, "seconds");
    Emitter.emit('BREADCRUMB_DATE', moment(startAtZoned).format('DD/MM/YYYY') + ' ' + moment(startAtZoned).format('HH:mm'));
  }

  compareTimeZones(timezone1, timezone2, startDate, endDate) {

    if(!timezone1 || !timezone2 || !startDate || !endDate) {
      throw new Error('All parameters timezone1, timezone2, startDate, endDate are required');
    }
    const start = moment(startDate);
    const end = moment(endDate);
    
    for (let date = start; date.isBefore(end); date.add(1, 'days')) {
      const offset1 = moment.tz(date, timezone1).utcOffset();
      const offset2 = moment.tz(date, timezone2).utcOffset();
  
      if (offset1 !== offset2) {
        return false;
      }
    }
    
    return true;
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col className="pl-0" md={{ size: 3, offset: 1 }} xs={{ size: 12 }}>
            <Flatpickr
              key={this.state.key}
              className="form-control d-block"
              options={{
                locale: i18n.language == 'ca' ? Catalan : Spanish,
                inline: true,
                enable: this.props.enabledDates
              }}
              onChange={date => {
                this.selectDate({ date });
              }}
              onMonthChange={(selectedDates, dateStr, instance) => this.handleMonthChange(selectedDates, dateStr, instance) }
              onYearChange={(selectedDates, dateStr, instance) => this.handleYearChange(selectedDates, dateStr, instance)}
            />
          </Col>
          <Col className="hour-selection" xs={12} md={{ size: 5, offset: 1 }}>
            {this.props.loading ?
              <Loading />
              :
              <Row>
              {
                this.state.hours.map((hour, index) => (
                  <Col className="px-xs-0" key={index} md={4} xs={12}>
                    <div className="custom-radio">
                      <input id={'date-' + index} name="hours" type="radio" checked={this.state.hour == hour.formattedStartHour} value={hour.formattedStartHour} onChange={this.setHour.bind(this)} />
                      <label htmlFor={'date-' + index}>{hour.formattedStartHour}</label>
                    </div>
                  </Col>
                ))
              }
              {
                ((!this.state.hours.length && this.state.date) || !this.props.enabledDates.length) && (
                  <Col className="px-xs-0">
                    {this.props.t('sin_horarios')}
                  </Col>
                )
              }
              </Row>
            }
          </Col>
        </Row>
        {this.state.showDifferentTimeZoneMsg &&
          <Row style={{marginTop: "1rem"}}>
            <Col>
              <div className="alert alert-warning">
                <span className="mdi mdi-information"></span> {this.props.t("different_timezone_msg", {branchTimeZone: this.props.branchTimeZone})}
              </div>
            </Col>
          </Row>
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { loading, enabledDates, dates, error, branchTimeZone } = state.Home;
  return {
    loading, enabledDates, dates, error, branchTimeZone
  };
}

export default connect(mapStateToProps, {
  getDates
}, null, { forwardRef: true })(withTranslation('translations', { withRef: true })(Fecha));


